import React from "react";
import { navigate } from "gatsby-link";
import Recaptcha from 'react-google-recaptcha'
import Layout from "components/Layout";
import styled from "@emotion/styled";
import Button from "components/_ui/Button";
import dimensions from "styles/dimensions";

const SITE_RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY

const FormWrapper = styled("div")`
    width: auto;

    @media(min-width: ${dimensions.maxwidthTablet}px) {
        width: 500px;
        margin: 0 auto;
    }

    @media(min-width: ${dimensions.maxwidthWeb}px) {
        width: auto;
    }

    h1 {
        @media(max-width: ${dimensions.maxwidthTablet}px) {
            text-align: center;
        }
    }

    form {
        display: flex;
        padding: 1em;
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);

        @media(max-width: 949px) {
            flex-direction: column;
        }

        @media(max-width: ${dimensions.maxwidthMobile}px) {
            margin-bottom: 2em;
        }

        @media(min-width: 950px) {
            .form__inputs {
                flex-basis: 37%;
            }
    
            .form__textarea {
                flex-basis: 63%;
            }
        }

        .form__textarea {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 1em 0 0 0;

            @media(min-width:950px) {
                margin: 0 0 0 2em;
            }

            & > div:first-of-type {
                height: 100%;

                label,
                textarea {
                    height: 100%;
                }
            }
        }
    }

    .form-submit {
        text-align: center;

        button {
            width: 100%;
            font-size: 1.65em;
            padding: 0.25em 2em;
        }

        button[disabled] {
            cursor: not-allowed;
        }
    }

    .form-recaptcha {
        margin: 1em auto 0;
    }
`

const InputWrapper = styled("div")`
    &:not(:last-of-type) {
        padding-bottom: 1em;

        @media(min-width: ${dimensions.maxwidthTablet}px) {
            padding-bottom: 1.25em;
        }
    }

    label {
        display: flex;
        flex-direction: column;
    }

    span {
        font-size: 1.25em;
        font-weight: bold;

        @media(min-width: ${dimensions.maxwidthTablet}px) {
            font-size: 1.5em;
        }
    }

    input,
    textarea {
        font-size: 1.25em;
        padding: .25em;

        @media(min-width: ${dimensions.maxwidthTablet}px) {
            font-size: 1.5em;
        }
    }

    textarea {
        font-family: 'Inter var', sans-serif;
        min-height: 6em;

        @media(min-width: ${dimensions.maxwidthWeb}px) {
            min-height: inherit;
        }
    }
`

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

export default function Contact() {
    const [state, setState] = React.useState({})
    const recaptchaRef = React.createRef() // new Ref for reCaptcha
    const [buttonDisabled, setButtonDisabled] = React.useState(true)

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const recaptchaValue = recaptchaRef.current.getValue()

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                'g-recaptcha-response': recaptchaValue,
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    return (
        <Layout>
            <FormWrapper>
                <h1>Contact</h1>
                <form
                    name="contact"
                    method="post"
                    action="/thanks"
                    data-netlify="true"
                    data-netlify-recaptcha="true"
                    onSubmit={handleSubmit}
                >
                    <noscript>
                        <p>This form won’t work with Javascript disabled</p>
                    </noscript>
                    <div className="form__inputs">
                        <InputWrapper>
                            <label>
                                <span>Name</span>
                                <input type="text" name="name" autoComplete="off" required onChange={handleChange} />
                            </label>
                        </InputWrapper>
                        <InputWrapper>
                            <label>
                                <span>Email</span>
                                <input type="email" name="email" autoComplete="off" required onChange={handleChange} />
                            </label>
                        </InputWrapper>
                        <InputWrapper>
                            <label>
                                <span>Subject</span>
                                <input type="text" name="subject" autoComplete="off" required onChange={handleChange} />
                            </label>
                        </InputWrapper>
                    </div>
                    <div className="form__textarea">
                        <InputWrapper>
                            <label>
                                <span>Message</span>
                                <textarea name="message" autoComplete="off" required onChange={handleChange} />
                            </label>
                        </InputWrapper>
                        <div className="form-submit">
                            <Button type="submit" disabled={buttonDisabled}>Send</Button>
                        </div>
                        {/* Recaptcha */}
                        <div className="form-recaptcha">
                            <Recaptcha
                                ref={recaptchaRef}
                                sitekey={SITE_RECAPTCHA_KEY}
                                size="normal"
                                id="recaptcha-google"
                                onChange={() => setButtonDisabled(false)}
                            />
                        </div>
                    </div>
                </form>
            </FormWrapper>
        </Layout>
    )
};